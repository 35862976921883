import React, { memo, useContext, Fragment } from 'react';
import { Panel, Tabs } from '@sdflc/ui';
import { MenuHeader, DefaultLayout, PageLoading, CashDrawer, AuthWrapper } from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';
import { colors, scheduleColumns } from '../config';

const CashDrawerPage = memo(() => {
  return (
    <Fragment>
      <DefaultLayout title='Cash Drawer Counter'>
        <MenuHeader title='Cash Drawer Counter' />
        <Panel padding={'20px'} overflow='auto'>
          <AuthWrapper>
            <Tabs onClick={() => {}}>
              <Tabs.Tab id='counting' name='Counting' variant='default'>
                <CashDrawer />
              </Tabs.Tab>
              <Tabs.Tab id='withdrawal' name='Withdrawal'>
                <CashDrawer isWithdrawal withdrawTo={140} />
              </Tabs.Tab>
            </Tabs>
          </AuthWrapper>
        </Panel>
      </DefaultLayout>
    </Fragment>
  );
});

CashDrawerPage.displayName = 'CashDrawerPage';

CashDrawerPage.propTypes = {};

CashDrawerPage.defaultProps = {};

export default withProviders(CashDrawerPage);
